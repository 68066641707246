.contentIngestionProgress .ant-steps-item-icon {
  width: 14px !important;
  height: 14px !important;
  margin-top: 9px !important;
}
.ant-steps-item-tail {
  margin-left: 1px !important;
  width: 10px !important;
}
.ant-steps-item-title {
  font-size: 15px !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #d9d9d9 !important;
}
.ant-steps-item-tail::after {
  width: 3px !important;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
}
.contentIngestionProgress .ant-steps-item-finish .ant-steps-item-icon {
  background-color: green !important;
  border-color: green !important;
}
.contentIngestionProgress
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-icon {
  display: none !important;
}
.contentIngestionProgress .ant-steps-item-description .ant-steps-item-icon {
  background-color: transparent !important;
  border-color: #d9d9d9 !important;
}
.contentIngestionProgress
  .contentIngestionProgressSubsteps
  .ant-steps-item-tail {
  display: none !important;
}
.contentIngestionProgress
  .contentIngestionProgressSubsteps
  .ant-steps-item-icon {
  display: none !important;
}
.contentIngestionProgressSubsteps .ant-steps-item-container {
  height: 30px !important;
  margin-top: 10px !important;
}

.contentIngestionProgress
  .contentIngestionProgressSubsteps
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-icon {
  display: none !important;
}
.contentIngestionProgress .ant-steps-item-finish .ant-steps-item-icon {
  background-color: green !important;
  border-color: green !important;
}

.contentIngestionProgressSubsteps .ant-steps-item-icon,
.contentIngestionProgressSubsteps .ant-steps-item-tail {
  display: none !important;
}
