.localFilesComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.loaclFilesComponentHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.localFilesComponentSubHeadder {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.localFilesComponentSubHeadder img {
  width: 30px;
  height: 30px;
}
.localFilesComponentSubHeadder h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
  margin-left: 14px;
}
.loaclFilesComponentHeader .ant-btn {
  height: 40px;
  align-items: center;
}
.localFilesComponentSearch {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.localFilesComponentSearchHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.localFilesComponentSearchHeader h5 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 450;
  color: #000000;
  margin: 0px;
}
.localFilesComponentSearchHeader img {
  width: 24px;
  height: 24px;
  margin-top: 6px;
}
.localFilesComponentSubSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.localFilesComponentSubSearch .outerSpan {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #62646a;
  margin-right: 16px;
}
.localFilesComponentSubSearch .innerSpan {
  font-size: 18px;
  margin-left: 6px;
  font-weight: 500;
  color: #000000;
}
.localFilesComponentTableContent {
  width: 100%;
  margin-top: 20px;
  height: 450px;
  max-height: 450px;
}
.localFilesComponentTableContent .ant-table-container {
  justify-content: center;
  margin-top: 0.5rem;
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
}
.centered-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.localFilesComponentTableContent .ant-table-cell {
  padding: 10px !important;
}
