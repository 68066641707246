.contentSourcesAddition {
  display: flex;
  flex-direction: column;
}
.contentSourcesAdditionHeading {
  display: flex;
  flex-direction: column;
}
.contentSourcesAdditionHeading h1 {
  width: 89px;
  height: 28px;
  text-align: left;
  margin: 0px;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
  color: #070707;
}
.contentSourcesAdditionHeading p {
  width: 22vw;
  height: 36px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #858795;
}
.addingContentSourcesAddition {
  display: flex;
  flex-direction: row;
  height: 99vh;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 16px #00000008;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.addingContentAdditionheading {
  border-right: 2px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 18vw;
  height: 100%;
  padding-left: 20px;
}
.addingContentAdditionheading .addingContentAdditionheadingh4tag {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 0px;
  margin-bottom: 10px;
}
.addedContentTypes {
  margin-top: 10px;
  width: 16vw;
  height: 90%;
  max-height: 90%;
  overflow-y: auto;
}
.addedContentTypesDisplay {
  padding: 20px;
  width: 80%;
}
.addingContentSources {
  display: flex;
  flex-direction: row;
  height: 98vh;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 16px #00000008;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.addingContentheading {
  border-right: 2px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 18vw;
  height: 100%;
  padding-left: 20px;
}
.addingContentheading h4 {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 0px;
  margin-bottom: 10px;
}

.contentTypesImages {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.contentTypesImages h5 {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
}
.contentSourcesimage {
  width: 46px;
  height: 40px;
}
.contentSouresImages {
  display: flex;
  width: 500px;
  flex-direction: row;
  justify-content: space-evenly;
}
.contentTypesImagesPara {
  width: 60%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #858795;
}
