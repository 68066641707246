.webSiteConnectorsInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.webSiteConnectorsInput .webSiteConnectorsInput1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.webSiteConnectorsInput1 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.websiteConnectorsAuth {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.websiteConnectorSwitchContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.websiteConnectorsAuth .websiteConnectorSwitch {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-right: 10px;
}
.websiteConnectorsAuth h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
}
.webSiteConnectorsInput2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.webSiteUserName {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.webSitePassword {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.webSiteConnectorsInput2 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
