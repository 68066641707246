.webSiteConnectorsUrl {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.webSiteConnectorsUrl h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.webSiteConnectorsUrlInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.websiteurlInput {
  width: 81%;
  height: 32px;
}
.websiteurlInput Input {
  height: 32px;
}
.webSiteConnectorsUrlInput .ant-input-group-addon {
  border: none;
  padding: 0px;
}
.websiteurlInputButton {
  border-radius: 0px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: #076ad9;
  font-size: 15px;
  align-items: center;
  padding-top: 3px;
  height: 32px;
}
.websiteurlUpload {
  height: 32px;
  color: #076ad9;
  border-color: #076ad9;
}
