.answerConfigComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.answerConfigComponent .answerConfigInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.answerDocumentGroupId {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.answerAPIServer {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.answerDocumentGroupId h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  /* margin-top: 10px; */
  margin-bottom: 4px;
}
.answerAPIServer h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  /* margin-top: 10px; */
  margin-bottom: 4px;
}
.answerConfigComponent h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.answersAccessToken h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px !important;
  margin-bottom: 4px;
}
