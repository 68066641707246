.sharePointConnectors {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.sharePointConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.sharePointConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.sharePointConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.sharePointConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.sharePointLogo img {
  width: 64px;
  height: 64px;
}
.sharePointConnectorsInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.sharePointConnectorsInput .sharePointConnectorsInput1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sharePointConnectorsInput1 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.sharePointConnectorsInput2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sharePointClient {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.sharePointClient1 {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.sharePointConnectorsInput2 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.sharePointButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.sharePointButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}
.stepTwoSharePoint {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepTwoSharePointHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.stepTwoSharePointContent {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.stepTwoSharePointContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepTwoSharePointContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepTwoSharePointImage img {
  width: 64px;
  height: 64px;
}
.stepTwoCheckBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stepTwoCheckBox h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwoCheckBox .stepTwoCheckBoxContent {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  width: 100%;
  height: 340px;
  max-height: 340px;
  overflow-y: auto;
}
.stepTwoCheckBox .stepTwoSharepointNodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sharePointCheckBoxDiv {
  margin-left: 20px;
  margin-right: 20px;
}
.stepTwoSharePointButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.stepTwoSharePointButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}
.stepTwoCheckBoxContent .sharePointCheckBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  border-bottom: 1px solid #d1d4d8;
  padding: 10px;
}
.sharePointCheckBox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 1px solid #1f56ae;
  border-radius: 1px;
}
.stepThreeSharePoint {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepThreeSharePointHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepThreeSharePointContent {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.stepThreeSharePointContent h3 {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepThreeSharePointContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepThreeSharePointImage img {
  width: 64px;
  height: 64px;
}
.stepThreeSharePointDocument {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  background: #ffffff;
}
.stepFoursharePoint {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepFoursharepointConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepFoursharePointConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 78%;
}
.stepFoursharePointConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepFoursharePointConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepFoursharePointDocPropertiesHeading h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepFoursharePointPropertiesSetting {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 370px;
  max-height: 370px;
  overflow-y: auto;
}
.stepFoursharePointButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.stepFoursharePointButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}
