.WebsiteConnectorTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.webSiteConnectors {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.websiteConnectorTab {
  margin-top: 10px;
}
.WebSiteConnectorsSourceNameInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}
.WebSiteConnectorsSourceNameInput h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.webSiteConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.webSiteConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.webSiteConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.webSiteConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.webSiteLogo img {
  width: 64px;
  height: 64px;
}
.webSiteButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.webSiteButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
}
.stepTwowebSite {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepTwowebSiteConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepTwowebSiteConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.stepTwowebSiteConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepTwowebSiteConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepTwowebSiteCheckBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stepTwowebSiteCheckBox h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwowebSiteCheckBox .stepTwowebSiteCheckBoxContent {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.webSiteCheckBoxDiv {
  padding: 4px;
}
.stepTwowebSiteCheckBoxContent .webSiteCheckBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px;
}
.webSiteCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border: 1px solid #1f56ae;
  border-radius: 1px;
}
.webSiteCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}
.stepTwowebSiteAdvancedFilters {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}
.stepTwowebSiteAdvancedFilters h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwowebSiteAdvancedFiltersCheckBox {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
}
.stepTwowebSiteCustomInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}
.stepTwowebSiteCustomInput h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}

.stepTwowebSiteButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.stepTwowebSiteButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
}
.stepTwowebsite {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepTwowebsiteHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.stepTwowebsiteContent {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.stepTwowebsiteContent h3 {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepTwowebsiteContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepTwowebsiteImage img {
  width: 64px;
  height: 64px;
}
.stepTwoCheckBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stepTwoCheckBox h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 4px;
}
.stepTwoCheckBox .stepTwoTableChechBox {
  width: 100%;
  height: 400px;
  max-height: 400px;
}
.stepTwoTableChechBox .ant-table-container {
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
}
.stepTwoTableChechBox .ant-table-thead {
  border-radius: 10px;
}
.stepTwoTableChechBox .ant-table-thead .ant-table-cell {
  padding: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  color: #858795;
}
.stepTwoTableChechBox .ant-table-tbody {
  height: 30px !important;
}
.stepTwoTableChechBox .ant-table-tbody .ant-table-cell {
  padding: 0px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.stepTwoTableChechBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border: 1px solid #1f56ae;
  border-radius: 1px;
}
.stepTwowebsitButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
  margin-top: 22px;
}
.stepTwoCheckBox .stepTwoWebsiteConnectorHeading {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.stepTwoCheckBox .stepTwoWebsiteConnectorHeading .stepTwoWebsiteConnectorUrls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.stepTwoCheckBox
  .stepTwoWebsiteConnectorHeading
  .stepTwoWebsiteConnectorUrls
  h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 4px;
}
.stepTwoWebsiteConnectorUrls .ant-input-affix-wrapper {
  height: 28px !important;
  padding: 4px !important;
}

.websiteConnectorTabs .ant-tabs-nav {
  margin-bottom: 12px;
  margin-top: 12px;
}
.websiteConnectorTabs .ant-tabs-tab {
  margin: 0px !important;
  border-radius: 0px !important;
}
.ant-tabs-tab[data-node-key="1"] {
  border-left-width: 0px !important;
}
.websiteConnectorTabs .ant-tabs-nav .ant-tabs-tab {
  background: none !important;
}
.websiteConnectorTabs .ant-tabs-tab {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  background: none;
}
/* .websiteConnectorTabs .ant-tabs-tab-active {
  border: 2px solid #d9d9d9 !important;
  border-bottom: none !important;
  border-radius: 5px 5px 0 0 !important;
} */
.websiteConnectorTabs .ant-tabs-tab-active::before {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background: #076ad9;
  border-radius: 2px 2px 0 0;
}
.websiteConnectorTabs .ant-tabs-ink-bar {
  display: none;
}
.websiteConnectorTabs .ant-tabs-tab-active {
  background-color: #fff;
}
.websiteConnectorTabs .ant-tabs-tab {
  padding: 10px 16px;
}
