.sharePointDocuments .ant-tabs-nav {
  margin: 0px !important;
}
.sharePointDocuments .ant-tabs-nav-wrap {
  width: 100%;
  height: 52px;
  margin: 0px;
  border-bottom-color: transparent;
  /* margin-left: 20px;
  margin-right: 20px; */
}
.sharePointDocuments .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 10px;
}
.sharePointDocuments .ant-tabs .ant-tabs-tab-btn {
  font-size: 17px;
  letter-spacing: 0px;
  padding: 14px;
}
.sharePointDocuments .ant-tabs-tab.ant-tabs-tab-active {
  border-top-color: #e8e8e8 !important;
}
.sharePointDocuments .ant-tabs-ink-bar {
  background-color: transparent !important;
}
.sharePointDocuments .ant-tabs-tab:focus,
.ant-tabs-tab-active:focus {
  box-shadow: none;
}
.sharePointDocuments .ant-tabs-tab:hover {
  border-color: #e8e8e8;
}
.sharePointDocuments .ant-tabs-tab.ant-tabs-tab-active {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
/* Default state for all tabs */
.sharePointDocuments .ant-tabs-tab {
  border: 1px solid transparent;
  border-bottom-color: transparent;
}

/* Add left border for the first tab */
.sharePointDocuments .ant-tabs-tab:first-child {
  border-left: 1px solid #e8e8e8;
}

/* Add right border for the last tab */
.sharePointDocuments .ant-tabs-tab:last-child {
  border-right: 1px solid #e8e8e8;
}

/* Active tab styles */
.sharePointDocuments .ant-tabs-tab.ant-tabs-tab-active {
  border-top: 1px solid transparent !important;
  border-left: 1px solid #e8e8e8 !important;
  border-right: 1px solid #e8e8e8 !important;
}
.sharePointDocuments .ant-tabs-tab.ant-tabs-tab-active:first-child {
  border-left: 1px solid transparent !important;
}

.sharePointDocuments .ant-tabs-tab.ant-tabs-tab-active:last-child {
  border-right: 1px solid transparent !important;
}

/* Override the active tab's bottom border color */
.sharePointDocuments .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.sharePointDocuments .ant-table-container {
  width: 100%;
  justify-content: center;
  padding: 10px;
  height: 340px;
  max-height: 340px;
  overflow-y: auto;
}
.sharePointDocuments .ant-table-thead {
  border-radius: 10px;
}
.sharePointDocuments .ant-table-thead .ant-table-cell {
  padding: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.sharePointDocuments .ant-table-tbody {
  height: 30px !important;
}
.sharePointDocuments .ant-table-tbody .ant-table-cell {
  padding: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.sharePointDocuments .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: transparent !important;
}
.sharePointDocuments .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  display: none;
}
.sharePointDocumentTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
