.serviceNowConnectors {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.serviceNowConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.serviceNowConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 78%;
}
.serviceNowConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.serviceNowConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.serviceNowLogo img {
  width: 64px;
  height: 64px;
}
.serviceNowConnectorsInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.serviceNowConnectorsInput .serviceNowConnectorsInput1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.serviceNowConnectorsInput1 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.serviceNowConnectorsInput2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.serviceNowUserName {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.serviceNowPassword {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.serviceNowConnectorsInput2 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.serviceNowButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.serviceNowButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}
.stepTwoserviceNow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepTwoserviceNowConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepTwoserviceNowConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 78%;
}
.stepTwoserviceNowConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepTwoserviceNowConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepTwoServiceNowCheckBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stepTwoServiceNowCheckBox h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwoServiceNowCheckBox .stepTwoServiceNowCheckBoxContent {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.serviceNowCheckBoxDiv {
  padding: 4px;
}
.stepTwoServiceNowCheckBoxContent .serviceNowCheckBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px;
}
.serviceNowCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border: 1px solid #1f56ae;
  border-radius: 1px;
}
.serviceNowCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}
.stepTwoServiceNowAdvancedFilters {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}
.stepTwoServiceNowAdvancedFilters h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwoServiceNowAdvancedFiltersCheckBox {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
}
.stepTwoServiceNowCustomInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
  height: 112px;
  overflow-y: auto;
}
.stepTwoServiceNowCustomInput h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}

.stepTwoServiceNowButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.stepTwoServiceNowButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}

.stepThreeserviceNow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepThreeserviceNowConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepThreeserviceNowConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 78%;
}
.stepThreeserviceNowConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepThreeserviceNowConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepThreeserviceNowDocPropertiesHeading h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepThreeserviceNowDocPropertiesSetting {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 370px;
  max-height: 370px;
  overflow-y: auto;
}
.stepThreeServiceNowButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.stepThreeServiceNowButtons Button {
  width: 80px;
  height: 34px;
  margin-right: 10px;
}
