.SalesForceComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.SalesForceComponentHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.SalesForceComponentSubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}
.SalesForceComponentSubHeader img {
  width: 30px;
  height: 30px;
}
.SalesForceComponentSubHeader h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
  margin-left: 14px;
}
.SalesForceComponentHeaderLastSynced {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SalesForceComponentHeaderLastSynced span {
  font-size: 14px;
}
.salesForceSpanLastSynced {
  color: #62646a;
  margin-right: 6px;
}
.SalesForceComponentMetrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  border-color: #dbdbdb;
  margin-top: 18px;
}
.SalesForceComponentMetrics span {
  font-size: 14px;
  color: #62646a;
}
.SalesForceComponentMetrics h5 {
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
}
.SalesForceComponentMetrics1 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  border-right: 2px solid #dbdbdb;
}
.SalesForceComponentMetrics2 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  border-right: 2px solid #dbdbdb;
}
.SalesForceComponentMetrics3 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
}
.SalesForceComponentFilter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
}
.SalesForceComponentFilterHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SalesForceComponentFilterHeader img {
  width: 24px;
  height: 24px;
  margin-top: 6px;
}
.SalesForceComponentTable {
  width: 100%;
  margin-top: 10px;
  height: 450px;
  max-height: 450px;
}
.SalesForceComponentTable .ant-table-cell {
  padding: 10px !important;
}
.SalesForceComponentTable .ant-table-container {
  justify-content: center;
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
}
.SalesForceComponentTable .ant-table-thead > tr > th {
  background-color: #f0f0f0;
  color: #858795;
  font-weight: 400;
}

.SalesForceComponentTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
}

.SalesForceComponentTable .ant-table-cell:last-child {
  text-align: left; /* Align "View" text to the right */
}
.SalesForceComponentTable .ant-table-cell a {
  color: #1890ff; /* Color for the "View" link */
  text-decoration: none; /* Remove underline from the "View" link */
}
