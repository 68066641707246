.WebsiteComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.WebsiteComponentHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.WebsiteComponentSubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}
.WebsiteComponentSubHeader img {
  width: 30px;
  height: 30px;
}
.WebsiteComponentSubHeader h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
  margin-left: 14px;
}
.WebsiteComponentHeaderLastSynced {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.WebsiteComponentHeaderLastSynced span {
  font-size: 14px;
}
.WebsiteSpanLastSynced {
  color: #62646a;
  margin-right: 6px;
}
.WebsiteComponentMetrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  border-color: #dbdbdb;
  margin-top: 18px;
}
.WebsiteComponentMetrics span {
  font-size: 14px;
  color: #62646a;
}
.WebsiteComponentMetrics h5 {
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
}
.WebsiteComponentMetrics1 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  border-right: 2px solid #dbdbdb;
}
.WebsiteComponentMetrics2 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  border-right: 2px solid #dbdbdb;
}
.WebsiteComponentMetrics3 {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
}
.WebsiteComponentFilter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
}
.WebsiteComponentFilterHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.WebsiteComponentFilterHeader img {
  width: 24px;
  height: 24px;
  margin-top: 6px;
}
.WebsiteComponentFilterHeader span {
  margin-right: 10px;
}
.WebsiteComponentTable {
  width: 100%;
  margin-top: 10px;
  height: 430px;
  max-height: 430px;
}
.WebsiteComponentTable .ant-table-cell {
  padding: 8px !important;
}
.WebsiteComponentTable .ant-table-container {
  justify-content: center;
  height: 430px;
  max-height: 430px;
  overflow-y: auto;
}
.WebsiteComponentTable .ant-table-thead > tr > th {
  background-color: #f0f0f0;
  color: #858795;
  height: 40px !important;
  font-weight: 400;
}

.WebsiteComponentTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
}

.WebsiteComponentTable .ant-table-cell:last-child {
  text-align: left; /* Align "View" text to the right */
}
