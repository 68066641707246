.kebabMenu {
  height: 16px;
}
.kebabMenu.customDropdown > .ant-dropdown {
  inset: 18px auto auto 380px !important;
  width: 240px !important;
}
.kebabMenuItems .ant-dropdown-menu-item {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  align-items: center !important;
  font-size: 14px;
}
.kebabMenuItemsImage {
  height: 18px;
}
.KebabMenuModalSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.KebabMenuModalSection .KebabMenuModalHeading {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.KebabMenuModalHeading h4 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.KebabMenuModalHeading p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  width: 64%;
  margin: 0px;
}
.kebabMenuModal .ant-modal-content {
  padding: 0px;
  width: 100%;
  height: 100%;
}
.kebabMenuModal .ant-modal-header {
  height: 48px;
  border-bottom: 2px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin: 0px;
}
.kebabMenuModal .ant-modal-title {
  width: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.kebabMenuModal .ant-modal-title h3 {
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
}

.kebabMenuModal .modal-close-button {
  width: 80px;
  height: 30px;
  font-size: 16px;
  border: 1px solid;
  background: #ffffff;
  border: 1.5px solid #076ad9;
  border-radius: 4px;
  color: #076ad9;
}
.KebabMenuModalBusinessRuleUpdating {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.KebabMenuModalBusinessRuleUpdating h5 {
  text-align: left;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.KebabMenuModalBusinessRuleCode {
  position: relative;
  width: 95.5%;
  height: 360px;
  max-height: 360px;
  overflow-y: auto;
  background-color: #f3f3f3;
  border-radius: 6px;
  font-size: 12px;
  padding: 12px;
}
.KebabMenuModalBusinessRuleCode pre {
  margin-top: 0px;
  color: #5c5c5c;
}
.KebabMenuModalBusinessRuleCode .copyButton {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: #858795;
}

.jobDetailsModal .ant-modal-content {
  padding: 0px;
  width: 100%;
  height: 100%;
}
.jobDetailsModal .ant-modal-header {
  height: 48px;
  border-bottom: 2px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin: 0px;
}
.jobDetailsModal .ant-modal-title {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.jobDetailsModal .ant-modal-title h3 {
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
}

.jobDetailsModal .modal-close-button {
  width: 80px;
  height: 30px;
}
.jobDetailsModal .modal-close-button {
  width: 80px;
  height: 30px;
  font-size: 16px;
  border: 1px solid;
  background: #ffffff;
  border: 1.5px solid #076ad9;
  border-radius: 4px;
  color: #076ad9;
}
.JobDetailsModalSection {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 10px;
}
.JobDetailsModalSection .JobDetailsModalBasicInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.jobDetailsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  height: 40px;
}
.jobDetailsDiv .jobDetailsp {
  width: 20%;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  align-items: center;
  font-weight: 400;
}
.jobDetailsp p {
  margin: 0px;
}
.jobDetailsDiv .jobDetailsValue {
  width: 80%;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
}
.jobDetailsDivUrl {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
}
.jobDetailsDivUrl .jobDetailsp {
  width: 20%;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  align-items: center;
  font-weight: 400;
}
.jobDetailsDivUrl .jobDetailsValueUrl {
  width: 80%;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  background-color: #dbdbdb;
  padding: 8px;
  border-radius: 4px;
}
.KebabMenuModalBusinessSharepointCode {
  position: relative;
  width: 95.5%;
  height: max-content;
  overflow-y: auto;
  background-color: #f3f3f3;
  border-radius: 6px;
  font-size: 12px;
  padding: 12px;
}
.KebabMenuModalBusinessSharepointCode pre {
  margin-top: 0px;
  color: #5c5c5c;
}
.KebabMenuModalBusinessSharepointCode .copyButton {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: #858795;
}
