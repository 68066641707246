.salesForceConnectors {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.salesForceConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.salesForceConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 78%;
}
.salesForceConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.salesForceConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.salesForceLogo img {
  width: 64px;
  height: 64px;
}
.salesForceConnectorsInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.salesForceConnectorsInput .salesForceConnectorsInput1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.salesForceConnectorsInput1 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.salesForceConnectorsInput2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.salesForceOAuthClientID {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.salesForceOAuthClientSecret {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.salesForceConnectorsInput2 h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.salesForceButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.salesForceButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
}
.stepTwosalesForce {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}
.stepTwosalesForceConnectorsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.stepTwosalesForceConnectorsContent {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.stepTwosalesForceConnectorsContent h3 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.stepTwosalesForceConnectorsContent p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.stepTwosalesForceCheckBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.stepTwosalesForceCheckBox h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwosalesForceCheckBox .stepTwosalesForceCheckBoxContent {
  border: 1px solid #d1d4d8;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.salesForceCheckBoxDiv {
  padding: 4px;
}
.stepTwosalesForceCheckBoxContent .salesForceCheckBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px;
}
.salesForceCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border: 1px solid #1f56ae;
  border-radius: 1px;
}
.salesForceCheckBox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}
.stepTwosalesForceAdvancedFilters {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

}

.stepTwosalesForceCustomInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}
.stepTwosalesForceCustomInput h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stepTwosalesForceButtons {
  width: 18%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.stepTwosalesForceButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
}
