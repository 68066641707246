.contentingestiontype {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15.8vw;
  height: 7.6vw;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  cursor: pointer;
  opacity: 1;
  padding: 10px;
  transition: border-color 0.3s;
}
.contentingestiontype:hover {
  border-color: #1877ff;
}
.contenttypes {
  display: flex;
  flex-direction: column;
}
.contenttypes img {
  width: 3.4vw;
  height: 3.8vw;
}
.contenttypes h4 {
  font-size: 16px;
  margin: 0px;
  font-weight: 450;
  margin-top: 20px;
}
.contentarrow .icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: #f0f0f0;
  border-radius: 50%;
  transition: border-color 0.3s;
}
.contentingestiontype:hover .icon-circle {
  background-color: #1877ff;
}
.icon-circle svg {
  color: #d1d4d8;
  transition: color 0.3s;
}
.contentingestiontype:hover .icon-circle svg {
  color: white;
}
.contentingestiontype:not(.contentSourceDisabled):hover {
  border-color: #1877ff;
}

.contentingestiontype:not(.contentSourceDisabled):hover .icon-circle {
  background-color: #1877ff;
}

.contentingestiontype:not(.contentSourceDisabled):hover .icon-circle svg {
  color: white;
}
