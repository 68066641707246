.filesSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
.filesContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filesContentHeading {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.filesContentHeading h3 {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px;
  font-weight: 450;
}
.filesContentHeading p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #62646a;
  margin: 0px;
}
.filesLogo img {
  width: 64px;
  height: 64px;
}
.fileSourceName {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}
.fileSourceName h4 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 6px;
}
.addFiles {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.addFiles h3 {
  text-align: left;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 6px;
}
.addFiles .uploadFilesContent {
  width: 100%;
  height: 78px;
}
.addedFilesList {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  align-items: center;
  justify-content: space-between;
  background: #d1d4d8;
  border-radius: 6px;
  margin-top: 10px;
}
.addedFileSource {
  width: 60%;
  margin-left: 6px;
  align-items: center;
}
.addedFileSource h3 {
  margin: 0px;
  color: #858795;
}
.addedFileDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  align-items: center;
  margin-right: 6px;
}
.addedFileDescription h3 {
  margin: 0px;
  color: #858795;
}
.addedFile {
  height: 40px;
  border-bottom: 1px solid #d1d4d8;
  width: 100%;
}
.allTheFilesList {
  height: 210px;
  max-height: 210px;
  overflow-y: auto;
}
.allTheFilesList .addedFile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.allTheFilesList .addedFileSource {
  width: 60%;
  align-items: center;
}
.allTheFilesList .addedFileDescription {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}
.addFilesButtons {
  display: flex;
  flex-direction: row;
  width: 18%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}
.addFilesButtons Button {
  width: 70px;
  height: 34px;
  margin-right: 10px;
}
