.addedContentMain {
  display: flex;
  flex-direction: row;
  width: 96%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #e4e4e4;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
}
.addedContentHeader {
  display: flex;
  flex-direction: column;
}
.addedContentHeader h6 {
  font-size: 12px;
  font-weight: 400;
  color: #7f8688;
  margin: 0px;
}
.addedContentHeader h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  margin-top: 10px;
}
.name {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.addedContentImage img {
  width: 28px;
  height: 28px;
}
.addedContentMain.selected {
  background-color: #ecf4fc;
}

.addedContentMain .selectedName {
  color: #066ad9;
}
.addedContentMain {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addedContentMain:hover {
  background-color: #ecf4fc;
}
.addedContentMain .selectedName :hover {
  color: #066ad9;
}
.addedContentMain:hover h6 {
  color: #858795;
}
