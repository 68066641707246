.addnewcontentmodalbutton .enabled-button {
  width: 16vw;
  height: 50px;
  border: 1px solid #d1d4d8;
  border-radius: 4px;
  opacity: 1;
  background-color: #ffffff;
  margin-top: 5px;
  font-size: 14px;
  color: #066ad9;
}

.addnewcontentmodalbutton .disabled-button {
  width: 16vw;
  height: 50px;
  border: 1px solid #d1d4d8;
  border-radius: 4px;
  opacity: 1;
  background-color: #ffffff;
  margin-top: 5px;
  font-size: 14px;
  color: #066ad9;
  pointer-events: none;
  opacity: 0.4;
}

.customModal .ant-modal-content {
  padding: 0px;
  width: 100%;
  height: 100%;
}
.customModal .ant-modal-header {
  height: 48px;
  border-bottom: 2px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin: 0px;
}
.customModal .ant-modal-title {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.customModal .ant-modal-title h3 {
  margin: 0px !important;
  font-weight: 400;
  font-size: 18px;
}

.customModal .modal-close-button {
  width: 80px;
  height: 30px;
}
.customModal .modal-close-button {
  width: 80px;
  height: 30px;
  font-size: 16px;
  border: 1px solid;
  background: #ffffff;
  border: 1.5px solid #076ad9;
  border-radius: 4px;
  color: #076ad9;
}
.addNewContentModal {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.addNewContentModalHeading {
  width: 28.5vw;
  height: 83vh;
  border-right-style: solid;
  border-right-width: 2px;
  border-color: #dfdfdf;
  padding: 16px;
}
.contentSourceGrid {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
}
.contentSourceGridHeading h4 {
  font-size: 18px;
  margin: 0px;
  font-weight: 400;
  width: 250px;
}
.contentSourceGridHeading p {
  width: 550px;
  color: #62646a;
  font-size: 14px;
  font-weight: normal;
  margin-top: 0px;
}
.contentSourceMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 0.8rem;
}
.contentSourceMenu1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}
.contentSourceMenu2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}
.contentSourceMenu3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* for disabling the few content sources*/
.contentSourceDisabled {
  pointer-events: none;
  opacity: 0.4;
}
